.header {
    width: 100%;
    height: 70px;
    padding: 7.5px 40px;
    box-shadow: 3px 0 10px rgba(0,0,0,.3);
    position: relative;
    img {
        height: 55px;
    }
    .button {
        position: absolute;
        right: 30px;
        top: 50%;
        margin-top: -11px;
        color: rgb(71, 71, 71);
            &:hover {
                opacity: .7;
            }
    }
}
@media (max-width: 550px) {
    .header {
        padding: 7.5px 10px;
    }
}