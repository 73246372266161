#final-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    p {
        margin: 10px 0;
    }
    .color-red {
        color: chocolate;
    }

}
.row-btn {
    width: 8%;
    text-align: center;
    border-radius: 10px;
    border: 1px solid lightgray;
    // padding: 8px 0;
    margin: 10px 0 ;
    cursor:pointer;
}

.row-btn :active {
    border: gray;
}