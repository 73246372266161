body {
  letter-spacing: 1px;
  margin: 0;
  font-size: medium;
  font-family: 'Noto Sans TC', sans-serif;
}
body p {
  margin: unset;
}
body a {
  text-decoration: none !important;
  color: unset;
}
#content {
  border-radius: 10px;
  min-height: 75vh;
  margin: 0 auto;
}
#content #content-major {
  width: 70%;
  max-width: 1080px;
  margin: 0 auto;
}
@media (max-width: 900px) {
  #content #content-major {
    width: 90%;
  }
  body {
    font-size: 14px;
  }
}
@media (max-width: 450px) {
  body {
    font-size: small;
  }
  #content #content-major {
    width: 95%;
  }
}

#step-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 70px 10px;
  max-width: 900px;
  width: 70%;
  margin: 0 auto;
}
#step-frame .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}
#step-frame .step .step-t {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}
#step-frame .step .step-t .step-number {
  border: 3px #e6e6e6 solid;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e6e6e6;
  border-radius: 30px;
  font-size: 1.1em;
  font-weight: bold;
}
#step-frame .step .step-t .step-line {
  flex: 1 1;
  height: 4px;
  background-color: #e6e6e6;
}
#step-frame .step .step-t .step-line-none {
  flex: 1 1;
}
#step-frame .step .step-tip {
  color: #cecece;
  font-weight: 400;
  padding: 0 10px;
}
#step-frame .now-step .step-number {
  border-color: #232323 !important;
  color: white !important;
  background-color: #232323;
}
#step-frame .now-step .step-line:first-child {
  background-color: #232323;
}
#step-frame .now-step .step-tip {
  color: #232323;
  font-weight: bold;
}
#step-frame .final-step .step-number {
  border-color: #1ab2e1 !important;
  color: white !important;
  background-color: #1ab2e1 !important;
}
#step-frame .final-step .step-line:first-child {
  background-color: #1ab2e1 !important;
}
#step-frame .final-step .step-tip {
  color: #1ab2e1 !important;
  font-weight: bold !important;
}
#step-frame .step-done .step-t .step-line {
  background-color: #9ece44;
}
#step-frame .step-done .step-t .step-line:last-child {
  background-color: #9ece44;
}
#step-frame .step-done .step-t .step-number {
  border-color: #9ece44;
  color: white;
  background-color: #9ece44;
}
@media (max-width: 800px) {
  #step-frame {
    padding: 70px 10% 50px 10%;
    width: 90%;
    padding: 40px 0;
  }
}
@media (max-width: 500px) {
  #step-frame {
    display: none;
  }
}

#small-step-main {
  width: 95%;
  margin: 0px auto;
  position: relative;
  display: none;
  padding: 16px 3px;
  flex-direction: column;
}
#small-step-main #small-step-num {
  display: flex;
  align-items: center;
}
#small-step-main #small-step-num .small-step-line {
  flex: 1 1;
  height: 2px;
  background-color: #8c8c8c;
}
#small-step-main #small-step-num #circle {
  border-radius: 100px;
  height: 40px;
  width: 40px;
  background-color: #4c4c4c;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 1.1em;
  margin: 8px;
  color: white;
}
#small-step-main #small-step-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #8c8c8c;
  margin: 5px 0;
}
@media (max-width: 500px) {
  #small-step-main {
    display: flex;
  }
}

#result {
  display: flex;
  justify-content: flex-end;
  border: solid 1px #ccc;
  padding: 20px;
  bottom: 0;
  width: 100%;
  right: 0;
  background-color: white;
  margin-top: 100px;
}
#result #result-text {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#result #submit-button {
  margin-left: 15px;
  border-radius: 50px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  color: white;
  cursor: pointer;
}
#result #submit-button:hover {
  background-color: #595959;
}
#result #pre-button {
  border-radius: 100px;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  margin-left: 10px;
  color: white;
  background-color: white;
  color: #8f8f8f;
  cursor: pointer;
  font-weight: 400;
}
#result #pre-button .fas {
  margin-right: 5px;
}

#date-block .flatpickr-time {
  display: none;
}
#step1content {
  width: 90%;
  margin: 0 auto;
}
.block-t,
.block-b {
  border-radius: 5px;
  margin: 30px auto 20px auto;
  padding-bottom: 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.block-t .basic-info-title,
.block-b .basic-info-title {
  width: 50%;
  padding: 8px 30px;
  box-sizing: border-box;
  color: white;
  background-color: #426784;
  border-radius: 30px;
  border: solid 2px #426784;
  text-align: center;
}
.block-t .basic-info-content,
.block-b .basic-info-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 70px 60px 70px;
}
.block-t .basic-info-content #content-inner,
.block-b .basic-info-content #content-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}
.block-t .basic-info-content #content-inner #date-block,
.block-b .basic-info-content #content-inner #date-block {
  display: flex;
  font-weight: 500;
  background-color: #324f71;
  width: 450px;
  margin-right: 20px;
}
.block-t .basic-info-content #content-inner #date-block .default,
.block-b .basic-info-content #content-inner #date-block .default {
  padding: 7px 6px;
  background-color: white;
  width: 100%;
  text-align: center;
  border: 1px solid #426784;
}
.block-t .basic-info-content #content-inner #date-block .date-icon,
.block-b .basic-info-content #content-inner #date-block .date-icon {
  display: flex;
  align-items: center;
  margin: 0 9px 0 10px;
  color: white;
}
.block-t .basic-info-content #content-inner .check-block,
.block-b .basic-info-content #content-inner .check-block {
  display: flex;
  width: auto;
  justify-content: space-between;
  font-size: 0.9em;
}
.block-t .basic-info-content #content-inner .check-block .item-box,
.block-b .basic-info-content #content-inner .check-block .item-box {
  background-color: #e6edf3;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0 5px;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.block-t .basic-info-content #content-inner .check-block .item-box .container,
.block-b .basic-info-content #content-inner .check-block .item-box .container {
  width: unset;
  padding-right: unset;
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: unset;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 16px;
  line-height: 16px;
  color: #2a4156;
  font-weight: 400;
}
.block-t .basic-info-content #content-inner .check-block .item-box .container input,
.block-b .basic-info-content #content-inner .check-block .item-box .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.block-t .basic-info-content #content-inner .check-block .item-box .checkmark,
.block-b .basic-info-content #content-inner .check-block .item-box .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
}
.block-t .basic-info-content #content-inner .check-block .item-box .container:hover input ~ .checkmark,
.block-b .basic-info-content #content-inner .check-block .item-box .container:hover input ~ .checkmark {
  background-color: #ccc;
}
.block-t .basic-info-content #content-inner .check-block .item-box .container input:checked ~ .checkmark,
.block-b .basic-info-content #content-inner .check-block .item-box .container input:checked ~ .checkmark {
  background-color: #c42b30;
}
.block-t .basic-info-content #content-inner .check-block .item-box .checkmark:after,
.block-b .basic-info-content #content-inner .check-block .item-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.block-t .basic-info-content #content-inner .check-block .item-box .container input:checked ~ .checkmark:after,
.block-b .basic-info-content #content-inner .check-block .item-box .container input:checked ~ .checkmark:after {
  display: block;
}
.block-t .basic-info-content #content-inner .check-block .item-box .container .checkmark:after,
.block-b .basic-info-content #content-inner .check-block .item-box .container .checkmark:after {
  left: 5px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (max-width: 1200px) {
  #step1content {
    width: unset;
  }
}
@media (max-width: 700px) {
  .block-t .basic-info-content #content-inner {
    flex-direction: column;
    margin: 0;
    width: 70%;
    align-items: flex-start;
  }
  .block-t .basic-info-content #content-inner #date-block {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .block-t .basic-info-content #content-inner .check-block .item-box:first-child {
    margin: 0 10px 0 0;
  }
}
@media (max-width: 500px) {
  .block-t .basic-info-content {
    background-color: unset;
  }
  .block-t .basic-info-content #content-inner {
    flex-direction: column;
    margin: 0;
    width: 90%;
    align-items: flex-start;
  }
  #step1content {
    width: 100%;
    background-color: white;
    padding: 5px 0;
    margin-top: 20px;
    border-radius: 5px;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
  .block-t {
    border: unset !important;
    height: unset;
    margin: 20px auto;
  }
  .basic-info-title {
    display: none !important;
  }
  .block-t .basic-info-content #content-inner #date-block {
    background-color: #727272;
    color: #5e5e5e;
    border: unset;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .block-t .basic-info-content #content-inner .check-block {
    width: 100%;
    padding-bottom: 20px;
  }
  .block-t .basic-info-content #content-inner #date-block .fas {
    color: white;
  }
  .block-t .basic-info-content #content-inner .check-block .item-box {
    background-color: white;
    color: #7c7c7c;
    height: 35px;
    width: 100px;
    flex: 1 1;
    border-radius: 0px;
    box-shadow: 1px 1px 10px #eaeaea;
  }
  .block-t .basic-info-content #content-inner .check-block .item-box .container {
    padding-left: 29px;
  }
  .block-t .basic-info-content #content-inner .check-block .item-box .checkmark {
    background-color: white;
    border: 1px solid #bbb;
  }
}

input,
select {
  font-family: unset;
}

#num-block {
  display: flex;
  width: 100%;
  flex-direction: column;
}
#num-block .row {
  display: flex;
  margin: unset;
}
#num-block .row .row-item {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
}
#num-block .row .row-item .title {
  display: flex;
  align-items: center;
  flex: 1 1;
  justify-content: flex-start;
  color: #2a4156;
}
#num-block .row .row-item .title span {
  font-size: 0.8em;
  margin-left: 5px;
  color: #2a4156;
}
#num-block .row .row-item .select-num {
  width: 100px;
  height: 40px;
  background-color: #e6edf3;
  border-radius: 100px !important;
  border: unset;
  color: #2a4156;
  -webkit-border-radius: 0px;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
  flex: 1 1;
}
#num-block .row .row-item:first-child {
  margin-right: 110px;
}
#num-block .row:first-child {
  margin-bottom: 15px;
}
#num-block .small-notice {
  display: none;
  padding: 10px 0;
}
#num-block input {
  width: 70px;
  margin: 0 8px;
}
@media (max-width: 1100px) {
  .block-b .basic-info-title {
    flex-direction: column;
  }
  .block-b .basic-info-content #num-block .row .row-item:first-child {
    margin-right: 40px;
  }
}
@media (max-width: 1000px) and (min-width: 900px) {
  .block-b .basic-info-content #num-block .row .row-item .title {
    flex-direction: column;
    align-items: baseline;
  }
}
@media (max-width: 800px) {
  #num-block {
    width: 75%;
  }
  .block-b .basic-info-content #num-block .row .row-item .title {
    flex: unset;
  }
  .block-b .basic-info-content #num-block .row .row-item .select-num {
    flex: unset;
    width: 100px;
  }
  .block-b .basic-info-content #num-block .row .row-item {
    justify-content: space-between;
  }
}
@media (max-width: 650px) {
  .block-t .basic-info-content,
  .block-b .basic-info-content {
    padding: unset;
  }
  .block-b .basic-info-content #num-block .row .row-item {
    margin-bottom: 15px;
    justify-content: space-between;
  }
  .block-b .basic-info-content #num-block .row {
    flex-direction: column;
  }
  .block-b .basic-info-content #num-block .row .row-item:first-child {
    margin-right: 0px;
  }
  .block-b .basic-info-content #num-block {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .row-line {
    height: 1px;
    background-color: #e0e0e0;
    flex: 1 1;
    margin: 0 10px;
  }
  .block-b .basic-info-content {
    background-color: transparent;
  }
  .block-b .basic-info-content #num-block .small-notice {
    display: block;
    color: #d11313;
    font-size: 0.9em;
  }
  .block-b .basic-info-content #num-block {
    margin: 0;
    width: 90%;
  }
  .block-b .basic-info-content #num-block .row .row-item:first-child {
    margin-right: 0px;
  }
  .block-b .basic-info-content #num-block .row .row-item .title {
    flex: unset;
    color: #727272;
  }
  .block-b .basic-info-content #num-block .row .row-item .title span {
    color: #727272;
  }
  .block-b .basic-info-content #num-block .row .row-item {
    margin-bottom: 27px;
    justify-content: space-between;
  }
  .block-b .basic-info-content #num-block .row:first-child {
    margin-bottom: 0px !important;
  }
  .block-b .basic-info-content #num-block .row .row-item .select-num {
    flex: unset;
    background-color: white;
    color: #727272;
    border: 2px solid #e0e0e0;
    height: 35px;
  }
  .block-b .basic-info-title {
    flex-direction: column;
  }
  .block-b {
    border: unset !important;
    height: unset;
    margin: 10px auto;
  }
  .block-b .basic-info-content #num-block .row {
    flex-direction: column;
  }
}

#undecided-picked-tour-list {
  background-color: #f0f5f8;
  padding: 15px 35px;
  font-size: 0.9em;
}
#undecided-picked-tour-list .u-p-t-l-row {
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px 0;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-row-info {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-row-info .u-p-t-l-title {
  flex: 1 1;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-row-info .u-p-t-l-row-subinfo {
  display: flex;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-row-info .u-p-t-l-row-subinfo .u-p-t-l-days {
  min-width: 60px;
  text-align: center;
  color: #5f6368;
  font-size: 0.9em;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-row-info .u-p-t-l-row-subinfo .u-p-t-l-days .fas {
  margin-right: 5px;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-row-info .u-p-t-l-row-subinfo .u-p-t-l-price {
  min-width: 85px;
  text-align: center;
  color: #5f6368;
  font-size: 0.9em;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-delete {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-delete .fas {
  color: #d0d5d9;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-delete:hover {
  cursor: pointer;
}
#undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-delete:hover .fas {
  color: red;
}
#undecided-picked-tour-list #u-p-t-l-result {
  text-align: right;
  margin-top: 15px;
}
@media (max-width: 500px) {
  #undecided-picked-tour-list {
    padding: 10px 15px;
  }
  .u-p-t-l-row-info {
    flex-direction: column;
  }
  #undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-row-info .u-p-t-l-row-subinfo {
    margin-top: 5px;
  }
  #tour-list .tour-item .tour-info-r .intro {
    display: none;
  }
}

#guest-info {
  border: solid 1px #cccccc;
  margin: 15px auto;
  line-height: 40px;
  padding: 0px;
  border-radius: 20px;
  color: #858585;
  display: flex;
  flex-wrap: wrap;
  font-weight: 300;
}
#guest-info p {
  padding: 0 20px;
}

#tags-pick {
  margin: 15px auto 5px auto;
  display: none;
  flex-wrap: wrap;
}
#tags-pick .tags-pick-tag {
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 5px !important;
  border: 1px solid;
  font-size: 14px;
  white-space: nowrap;
  margin: 3px 0;
}
#tags-pick .tags-pick-tag:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
#tour-list {
  overflow: auto;
}
#tour-list .tour-item {
  padding: 15px;
  border-bottom: 1px solid #c0c0c0;
  display: flex;
}
#tour-list .tour-item .tour-info-l {
  width: 40%;
  height: 180px;
  display: flex;
}
#tour-list .tour-item .tour-info-l .tour-days {
  width: 40px;
  background-color: #474747;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: white;
  margin-right: 5px;
  font-weight: 400;
}
#tour-list .tour-item .tour-info-l .tour-days p:first-child {
  font-size: 20px;
}
#tour-list .tour-item .tour-info-l .tour-days p {
  font-size: 10px;
}
#tour-list .tour-item .tour-info-l .tour-photo {
  flex: 1 1;
  overflow: hidden;
  position: relative;
  background-size: cover !important;
}
#tour-list .tour-item .tour-info-l .tour-photo .tour-price {
  position: absolute;
  bottom: 0;
  background-color: rgba(35, 35, 35, 0.5);
  width: 100%;
  color: white;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
}
#tour-list .tour-item .tour-info-r {
  position: relative;
  width: 60%;
  padding-left: 10px;
}
#tour-list .tour-item .tour-info-r .small-info {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
#tour-list .tour-item .tour-info-r .small-info .days {
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 0.9em;
  padding: 3px 5px;
  color: #aaa;
  border: 1px #aaa solid;
  background-color: transparent;
  margin: 3px;
}
#tour-list .tour-item .tour-info-r .small-info .price {
  margin-left: 5px;
  color: white;
  align-items: center;
  background-color: #707070;
  border-radius: 10px;
  font-size: 0.9em;
  padding: 3px 5px;
  margin: 3px;
}
#tour-list .tour-item .tour-info-r .title {
  margin-top: 10px;
  font-weight: 600;
  font-size: 1.2em;
}
#tour-list .tour-item .tour-info-r .intro {
  margin-top: 10px;
  font-weight: 300;
  font-size: 0.9em;
  position: relative;
  line-height: 1.5em;
  height: 6em;
  overflow: hidden;
  color: #717373;
}
#tour-list .tour-item .tour-info-r .tour-item-tags {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
}
#tour-list .tour-item .tour-info-r .tour-item-tags .tour-tag {
  background-color: transparent;
  border-radius: 15px;
  padding: 5px 10px;
  margin-left: 5px;
  border: 1px solid #adadad;
  color: #999999;
  font-size: 12px;
  font-weight: 300;
  white-space: nowrap;
  margin-top: 3px;
}
#tour-list .tour-item:hover {
  opacity: 0.7;
  cursor: pointer;
}
@media (max-width: 500px) {
  #tour-list .tour-item .tour-info-r .tour-item-tags {
    display: none;
  }
  #tour-list .tour-item .tour-info-l .tour-photo .tour-price {
    display: none;
  }
  #tour-list .tour-item .tour-info-l .tour-days {
    display: none;
  }
  #tour-list .tour-item .tour-info-l {
    height: 100px !important;
  }
  #tour-list .tour-item .tour-info-l .tour-photo img {
    height: 100px;
  }
  #tour-list .tour-item {
    padding: 3px;
  }
  #tour-list .tour-item .tour-info-r .small-info {
    display: flex;
  }
}
@media (max-width: 800px) {
  #tour-list .tour-item .tour-info-l {
    height: 130px;
  }
  #tour-list .tour-item .tour-info-r .title {
    margin-top: 5px;
    font-size: 1.1em;
  }
  #tour-list .tour-item .tour-info-r .intro {
    height: 4.5em;
  }
}

#picked-tour-list {
  border: solid 1px #ccc;
}
#picked-tour-list .picked-tour {
  display: flex;
  border-bottom: 1px solid #ccc;
}
#picked-tour-list .picked-tour .picked-tour-l {
  display: flex;
  align-items: center;
  padding: 15px 0px 15px 25px;
  flex: 1 1;
}
#picked-tour-list .picked-tour .picked-tour-l .picked-tour-img {
  width: 80px;
  height: 80px;
  background-size: cover !important;
  border-radius: 50px;
  margin-right: 20px;
  border: solid white 2px;
  box-shadow: 0px 0px 7px #888888;
}
#picked-tour-list .picked-tour .picked-tour-l .picked-tour-img img {
  width: 130px;
}
#picked-tour-list .picked-tour .picked-tour-l .picked-tour-title {
  flex: 1 1;
  display: flex;
  align-items: center;
}
#picked-tour-list .picked-tour .picked-tour-r {
  padding: 3px;
  display: flex;
  align-items: center;
}
#picked-tour-list .picked-tour .picked-tour-r .tour-pick-day {
  border-color: #CCC;
  border-radius: 20px;
  padding: 8px;
  -webkit-appearance: none;
  -moz-text-align-last: center;
       text-align-last: center;
  width: 110px;
  margin: 5px;
  background-color: white;
}
#picked-tour-list .picked-tour .picked-tour-r .picked-tour-price {
  font-weight: bold;
  color: #297294;
  display: flex;
  align-items: center;
  width: 80px;
  margin: 5px;
  justify-content: center;
}
#picked-tour-list .picked-tour .picked-tour-r .picked-tour-r-btn {
  width: 50px;
  color: white;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  margin: 0 2px;
}
#picked-tour-list .picked-tour .picked-tour-r .picked-tour-days {
  background-color: black;
  background-color: #474747;
}
#picked-tour-list .picked-tour .picked-tour-r .picked-tour-days p:last-child {
  font-size: 0.8em;
}
#picked-tour-list .picked-tour .picked-tour-r .picked-tour-delete-btn {
  background-color: #c9655e;
}
#picked-tour-list .picked-tour .picked-tour-r .picked-tour-delete-btn:hover {
  background-color: red;
  cursor: pointer;
}
@media (max-width: 1024px) {
  #picked-tour-list .picked-tour .picked-tour-l {
    padding: 15px 25px 15px 25px;
  }
  #picked-tour-list .picked-tour .picked-tour-l .picked-tour-price {
    margin: 10px;
  }
  #picked-tour-list .picked-tour {
    flex-direction: column;
  }
  #picked-tour-list .picked-tour .picked-tour-l,
  #picked-tour-list .picked-tour .picked-tour-r {
    width: unset;
    align-items: unset;
    border-top: 1px solid #ccc;
  }
  #picked-tour-list .picked-tour .picked-tour-r {
    padding: 5px;
  }
  #picked-tour-list .picked-tour .picked-tour-r .tour-pick-day,
  #picked-tour-list .picked-tour .picked-tour-r .picked-tour-price {
    width: 35%;
    margin: unset;
  }
  #picked-tour-list .picked-tour .picked-tour-r .picked-tour-price {
    justify-content: flex-end;
    margin-right: 10px;
  }
  #picked-tour-list .picked-tour .picked-tour-r .picked-tour-r-btn,
  #picked-tour-list .picked-tour .picked-tour-r .picked-tour-r-btn {
    width: 15%;
    flex-direction: unset;
    flex: unset;
    height: unset;
  }
}
@media (max-width: 500px) {
  #picked-tour-list .picked-tour .picked-tour-l {
    padding: 10px 10px;
  }
  #picked-tour-list .picked-tour .picked-tour-l .picked-tour-img {
    margin-right: 20px;
  }
}

[aria-expanded="false"] .menu__icon--open {
  display: inline;
}
[aria-expanded="false"] .menu__icon--close {
  display: none;
}
[aria-expanded="true"] .menu__icon--open {
  display: none;
}
[aria-expanded="true"] .menu__icon--close {
  display: inline;
}
.menu__icon--close {
  display: none;
}
#accordion {
  width: 100%;
  border: unset;
}
#accordion .panel {
  box-shadow: 1px 1px 5px #ccc;
  border: unset;
}
#accordion .panel .panel-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #484e51;
  background-color: white;
  border: unset;
}
#accordion .panel .panel-heading #accordion-title-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
#accordion .panel .panel-heading #accordion-title-text #title-name {
  font-weight: bold;
  flex: 1 1;
}
#accordion .panel .panel-heading #accordion-title-text #title-tip {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#accordion .panel .panel-heading #accordion-title-text #title-tip #tip-text {
  font-size: 0.9em;
  color: #666666;
  margin-right: 10px;
}
#accordion .panel .panel-heading #accordion-title-text #title-tip .fa {
  margin-left: 5px;
  text-align: center;
}
#accordion .panel .panel-heading #accordion-title-text #title-tip #price-block {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  padding: 5px 10px;
  color: #1f3c80;
}
#accordion .panel .panel-heading #accordion-title-text #title-tip #price-block #price {
  margin: 0 3px 0 8px;
}
#accordion .panel .panel-heading #accordion-title-sign {
  text-align: center;
}
#accordion .panel:hover {
  box-shadow: 1px 1px 5px #aaa;
}
#customized-list {
  height: 100%;
  padding: 20px 25px;
  background-color: #f0f5f8;
}
#customized-list #customized-list-inner {
  position: relative;
  z-index: 0;
}
#customized-list #customized-list-inner .customized-list-item-outer {
  position: relative;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item {
  display: flex;
  padding: 10px;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-date {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 100px;
  background-color: #c5d7e2;
  border: 2px solid #c5d7e2;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-date p:first-child {
  font-size: 1.1em;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-date p:last-child {
  font-size: 0.9em;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content {
  font-size: 0.9em;
  color: #707070;
  flex: 1 1;
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 26px;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  height: 100%;
  align-items: center;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .title {
  display: flex;
  width: 75%;
  line-height: 20px;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub {
  width: 25%;
  display: flex;
  align-items: center;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub .c-l-i-d {
  flex: 1 1;
  text-align: center;
  color: #4C7D9B;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub .c-l-i-d .fas {
  margin-right: 5px;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub .c-l-i-p {
  flex: 1 1;
  text-align: center;
  color: #983436;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .repeat {
  background-color: #e9ecf1;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .repeat .title {
  color: #8a8c8c;
  font-weight: 300;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .repeat .sub .c-l-i-d {
  color: #8a8c8c;
  font-weight: 300;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .repeat .sub .c-l-i-p {
  color: #8a8c8c;
  font-weight: 300;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item:not(:last-child) {
  border-bottom: 1px solid #CCC;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item:first-child {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
#customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
#customized-list #customized-list-inner .customized-list-item-outer .c-l-i-empty .c-l-i-date {
  background-color: #f0f5f8;
  color: #c5d7e2;
}
#customized-list #customized-list-inner .customized-list-item-outer .c-l-i-empty .c-l-i-content {
  background-color: #f0f5f8;
  border: 2px dashed #c5d7e2;
  border-radius: 7px;
  box-sizing: border-box;
  color: #c5d7e2;
}
#customized-list #customized-list-inner .customized-list-item-outer:first-child .first-child-note {
  display: block !important;
  color: #b44c4a;
  font-size: 0.8em;
  line-height: 16px;
}
#customized-list #customized-list-inner .customized-list-item-outer:first-child .first-child-note i {
  margin-right: 5px;
}
#customized-list #customized-list-inner .customized-list-item-outer:last-child .last-child-note {
  display: block !important;
  color: #b44c4a;
  font-size: 0.8em;
  line-height: 16px;
}
#customized-list #customized-list-inner .customized-list-item-outer:last-child .last-child-note i {
  margin-right: 5px;
}
.customized-list-item-outer:not(:last-child) #customized-list-line {
  background-color: #c5d7e2;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 10px;
  left: 43px;
  z-index: -1;
}
#air-wrong {
  border: 1px solid #c45250;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px 20px;
  font-size: 0.9em;
  color: #c45250;
}
#air-wrong i {
  margin-right: 5px;
}
@media (max-width: 1024px) {
  #customized-list {
    padding: 20px 15px;
  }
  #customized-list #customized-list-inner .customized-list-item .c-l-i-date {
    width: 80px;
    height: 80px;
  }
  #customized-list #customized-list-inner .customized-list-item .c-l-i-date .two-tours {
    height: 40px;
  }
  #customized-list #customized-list-inner .customized-list-item-outer:not(:last-child) #customized-list-line {
    left: 43px;
    width: 4px;
  }
  #customized-list #customized-list-inner .customized-list-item .c-l-i-content {
    line-height: 20px;
    margin: 10px 12px;
  }
  #customized-list #customized-list-inner .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .title {
    width: 70%;
  }
  #customized-list #customized-list-inner .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub {
    width: 30%;
  }
}
@media (max-width: 750px) {
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .title {
    width: 65%;
    line-height: 18px;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub {
    width: 35%;
  }
  #accordion .panel .panel-heading {
    flex-direction: column;
    align-items: unset;
  }
  #accordion .panel .panel-heading #title-tip .fa {
    width: 100%;
  }
  #accordion .panel .panel-heading #accordion-title-sign {
    margin-top: 10px;
  }
  #accordion .panel .panel-heading #accordion-title-text #title-tip #price-block {
    margin: 0 0 0 10px;
    padding: 5px 0;
  }
}
@media (max-width: 500px) {
  #accordion .panel .panel-heading #accordion-title-text #title-tip #price-block {
    padding: 0;
    margin: 0 0 0 10px;
  }
  #accordion .panel .panel-heading {
    padding: 8px 12px;
  }
  #customized-list {
    padding: 3px;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item {
    padding: 6px;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content {
    margin: 0 7px;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-date {
    width: 60px;
    height: 60px;
    border: 2px solid #6a9cba;
    color: #4a7f9f;
    background-color: #f0f5f8;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-date p:last-child {
    padding-top: 3px;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .c-l-i-empty .c-l-i-date {
    color: #a5bbc7;
  }
  #customized-list #customized-list-inner .customized-list-item-outer:not(:last-child) #customized-list-line {
    left: 34px;
    width: 3px;
  }
  .c-l-i-empty .c-l-i-date,
  .c-l-i-empty .c-l-i-content {
    border: 1px dashed #c5d7e2 !important;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item {
    flex-direction: column;
    padding: 8px;
    align-items: unset;
    line-height: 20px;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .title {
    width: unset;
    line-height: 17px;
    margin-bottom: 3px;
    font-size: 1.1em;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub {
    width: unset;
    justify-content: flex-end;
  }
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub .c-l-i-d,
  #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub .c-l-i-p {
    flex: unset;
    width: 50px;
  }
  .c-l-i-date {
    line-height: 13px;
  }
}

.titleRequired:before {
  content: "*";
}
.required.error {
  border: 1px solid red !important;
}
.bill-block {
  border: 1px solid #CCC;
  margin-top: 25px;
  padding: 0 35px;
}
.bill-block .bill-inner-title {
  border-bottom: 1px solid #c2c2c2;
  padding: 20px 0 1px 3px;
  font-size: 1.1em;
}
.bill-block #notice {
  line-height: 26px;
  font-size: 0.9em;
  font-weight: 300;
}
.bill-block .bill-inner {
  margin: 20px auto;
  width: 87%;
}
.bill-block .bill-inner #list {
  color: #4d3a3d;
  text-align: center;
}
.bill-block .bill-inner #list .list-row {
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid #c5c1b5;
}
.bill-block .bill-inner #list .list-row .list-cell:nth-child(2) {
  flex: 1 1;
}
.bill-block .bill-inner #list .list-row .list-cell {
  width: 10%;
}
.bill-block .bill-inner #list .list-row:not(:first-child) .list-cell:nth-child(2) {
  text-align: left;
}
.bill-block .bill-inner #list .list-row:first-child {
  color: #232323;
}
.bill-block .bill-inner .form-row {
  display: flex;
  margin: 15px 0;
  justify-content: space-between;
}
.bill-block .bill-inner .form-row .row-item {
  line-height: 30px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: flex-start;
}
.bill-block .bill-inner .form-row .row-item .phone {
  display: flex;
}
.bill-block .bill-inner .form-row .row-item .phone .code {
  margin-right: 10px;
  color: #3970ca;
  font-size: 0.8em;
}
.bill-block .bill-inner .form-row .row-item .item-tip {
  color: #3970ca;
  font-size: 0.8em;
}
.bill-block .bill-inner .form-row .row-item #communication {
  display: flex;
}
.bill-block .bill-inner .form-row .row-item #communication select {
  margin-right: 10px;
}
.bill-block .bill-inner .form-row .row-item:not(:last-child) {
  padding-right: 20px;
}
.bill-block .bill-inner .form-row nobr {
  margin-right: 10px;
  font-size: 0.9em;
}
.bill-block .bill-inner .form-row select {
  background-color: white;
  height: 35px;
  border: solid 1px #CCC;
}
.bill-block .bill-inner .form-row input {
  border-radius: 5px;
  border: solid 1px #CCC;
  width: 100%;
}
.bill-block .bill-inner .form-row textarea {
  border-radius: 5px;
  border: solid 1px #CCC;
  width: 100%;
}
.bill-block .bill-inner .form-row form {
  display: flex;
}
.bill-block .bill-inner .form-row form .radio-item {
  flex: 1 1;
  display: flex;
}
.bill-block .bill-inner .form-row form input {
  width: unset;
}
.bill-block .bill-inner .form-row .hint {
  width: 100%;
  text-align: center;
  background: #f8f4ef;
  padding: 50px 20px;
}
.bill-block .bill-inner .form-row .row-btn {
  width: 45%;
  text-align: center;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 30px 0;
  cursor: pointer;
}
.bill-block .bill-inner .form-row .row-btn:hover {
  background-color: #f8f4ef;
  color: black;
}
.bill-block .bill-inner .form-row .row-btn:focus {
  background: #ffb355;
  color: white;
}
.bill-block .bill-inner .form-row .row-btn.disabled {
  background: gray;
}
.bill-block .bill-inner .form-row .center {
  margin: 0 auto;
}
.bill-block #bill-total-price {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-top: 5px;
}
.bill-block #bill-total-price p {
  color: chocolate;
}
.bill-block.hide {
  display: none;
}
@media (max-width: 750px) {
  .form-row.btn-row {
    flex-direction: row;
  }
  .form-row.btn-row .row-btn {
    width: 44% !important;
  }
  .form-row {
    padding-top: 5px;
    flex-direction: column;
  }
  .form-row .row-btn {
    width: 100% !important;
  }
  .bill-block {
    padding: 0 10px;
  }
  .bill-block .bill-inner .form-row .row-item {
    margin: 5px 0;
  }
  .row-item:not(:last-child) {
    padding-right: 0px !important;
  }
}
@media (max-width: 500px) {
  .bill-block .bill-inner {
    width: 100%;
  }
}

#total-price {
  background-color: #F8F3EF;
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: #553212;
  justify-content: center;
}
#total-price #total-price-content {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: flex-end;
  margin: 25px 0;
  font-weight: 300;
  border-bottom: 1px solid #f2e9e1;
  padding-bottom: 10px;
}
#total-price #total-price-content .extra-block {
  display: flex;
  align-items: flex-end;
  font-family: sans-serif;
}
#total-price #total-price-content .extra-block .fas {
  margin: 0 25px 3px 0;
}
#total-price #total-price-content .price-block {
  display: flex;
  align-items: baseline;
}
#total-price #total-price-content .price-block .price {
  font-size: 1.5em;
  padding: 3px 15px;
  font-weight: normal;
  background-color: #F2E9E1;
  border-radius: 30px;
  margin: 0 4px;
  font-family: 'Abel', sans-serif;
}
@media (max-width: 800px) {
  #total-price #total-price-content {
    flex-direction: column;
    align-items: flex-end;
    width: 90%;
    margin: 13px 0;
    padding-bottom: 5px;
  }
  #total-price #total-price-content .price-block {
    margin-top: 8px;
  }
  #total-price #total-price-content .extra-block {
    width: 100%;
  }
  #total-price #total-price-content .extra-block .fas {
    margin: 0 10px 3px 0;
  }
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #eaa05e;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 10px;
    left: 10px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 30px;
    height: 30px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 10px;
    left: 10px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 30px;
    height: 30px;
    opacity: 0;
  }
}

#final-info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
}
#final-info-content p {
  margin: 10px 0;
}
#final-info-content .color-red {
  color: chocolate;
}
.row-btn {
  width: 8%;
  text-align: center;
  border-radius: 10px;
  border: 1px solid lightgray;
  margin: 10px 0 ;
  cursor: pointer;
}
.row-btn :active {
  border: gray;
}

.header {
  width: 100%;
  height: 70px;
  padding: 7.5px 40px;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}
.header img {
  height: 55px;
}
.header .button {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -11px;
  color: #474747;
}
.header .button:hover {
  opacity: 0.7;
}
@media (max-width: 550px) {
  .header {
    padding: 7.5px 10px;
  }
}

.footer {
  padding: 50px 0;
  width: 100%;
  bottom: 0;
  background-color: #204865;
  color: #BDD3E3;
  font-size: 0.85em;
  display: flex;
  align-items: center;
}
.footer .footer-content {
  margin: 0 auto;
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.footer-logo {
  width: 25%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.footer-logo img {
  height: 80px;
}
.footer-text1 {
  width: 35%;
}
.footer-text2 {
  width: 30%;
}
.footer-pay {
  width: 10%;
}
.footer-pay img {
  margin-top: 10px;
}
@media (max-width: 550px) {
  .footer .footer-content {
    flex-direction: column;
    width: unset;
  }
  .footer-logo,
  .footer-text1,
  .footer-text2,
  .footer-pay {
    width: 100%;
    padding-bottom: 20px;
  }
}

.day-tour-block {
  width: 1000px;
  height: 500px;
  margin: 50px auto;
  color: white;
  position: relative;
}
.day-tour-block .day-tour-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -70%);
          transform: translate(-50%, -70%);
}
.day-tour-block .day-tour-center .day-tour-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.day-tour-block .day-tour-center .day-tour-text h1 {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  font-family: "Caveat";
}
.day-tour-block .day-tour-center .day-tour-text p {
  font-size: 25px;
  margin: 10px 0 20px 0;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  font-weight: 300;
}
.day-tour-block .day-tour-center .day-tour-text .day-tour-btn {
  width: 230px;
  height: 63px;
  background-color: #2E5370;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.6);
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  color: #dce3e9;
  -webkit-transition: background-color 500ms;
  transition: background-color 500ms;
}
.day-tour-block .day-tour-center .day-tour-text .day-tour-btn:hover {
  background-color: #4a83a1;
  color: white;
  cursor: pointer;
}
.day-tour-block .external {
  position: absolute;
  display: flex;
  align-items: center;
  height: 80px;
  bottom: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.7);
  padding: 0 20px;
}
.day-tour-block .external p {
  margin-right: 20px;
}
.day-tour-block .external .external-btn {
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  padding: 8px 10px 8px 10px;
  width: 130px;
}
.day-tour-block .external .external-btn i {
  margin-top: 4px;
  margin-left: 5px;
  -webkit-transition: margin-left 500ms;
  transition: margin-left 500ms;
}
.day-tour-block .external .external-btn img {
  width: 80px;
}
.day-tour-block .external .external-btn:hover i {
  margin-left: 11px;
  color: rgba(255, 255, 255, 0.9);
}
.day-tour-block .external .external-btn:hover {
  border: 2px solid rgba(255, 255, 255, 0.9);
  cursor: pointer;
}
@media (max-width: 500px) {
  .day-tour-block {
    width: 100%;
  }
  .day-tour-block .day-tour-center .day-tour-text h1 {
    font-size: 5em;
  }
}

.carousel-image {
  height: 450px;
  background-size: cover !important;
  background-position: center !important;
}


.tourDisplay {
  margin: 0 auto;
  width: 1000px;
}
.block {
  width: 100%;
}
.block .menu-block {
  position: relative;
  border-bottom: 2px solid rgba(51, 94, 124, 0.7);
  margin: 50px 0 30px 0;
}
.block .menu-block span {
  background-color: #335D7C;
  display: inline-block;
  padding: 6px 14px 3px 14px;
  border-radius: 5px 5px 0 0;
}
.block .menu-block span p {
  font-size: 1.2em;
  margin: unset;
  color: white;
  text-align: center;
}
.block .menu-block .subMenu-block {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-bottom: 5px;
}
.block .menu-block .subMenu-block .subMenu-item {
  display: inline-block;
  font-size: 1.2em;
  color: #334c5fba;
  margin-left: 10px;
  padding: 2px 10px;
}
.block .menu-block .subMenu-block .subMenu-item.active {
  border: 1px solid #375369;
  background-color: #c2d6e659;
  border-radius: 20px;
  color: #375369;
  font-weight: 500;
}
.block .tour-block {
  display: flex;
  flex-wrap: wrap;
}
.block .tour-block .tour-item {
  height: 257px;
  width: 230px;
  border: 1px #D5D5D5 solid;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
.block .tour-block .tour-item .tour-item-img {
  width: 100%;
  height: 160px;
  background-size: cover !important;
}
.block .tour-block .tour-item .tour-item-title {
  margin: 10px;
  height: 45px;
  color: #004B83;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.block .tour-block .tour-item .tour-item-day {
  padding: 0 10px;
  text-align: right;
}
.block .tour-block .tour-item .tour-item-day i {
  color: #454545;
}
.block .tour-block .tour-item .tour-item-day i span {
  padding-left: 3px;
  font-size: 0.8em;
}
.block .tour-block .tour-item .tour-item-day i:before {
  margin-right: 5px;
}
.block .tour-block .tour-item:hover {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.block .tour-block a:not(:nth-child(4n)) .tour-item {
  margin-right: 26px;
}
@media (max-width: 550px) {
  .tourDisplay {
    width: 100%;
  }
  .block .tour-block a {
    width: calc(50% - 10px);
    margin: 0 5px 10px 5px;
  }
  .block .tour-block a .tour-item {
    width: 100%;
    height: 245px;
    margin-bottom: 5px;
  }
  .block .tour-block a .tour-item .tour-item-title {
    height: 37px;
  }
  .block .tour-block a:not(:nth-child(4n)) .tour-item {
    margin-right: unset;
  }
  .block .tour-block a:not(:nth-child(2n)) .tour-item {
    margin-right: 5px;
  }
}

.tour-detail-banner {
  width: 80%;
  max-width: 1080px;
  margin: 0 auto;
  margin: 50px auto 40px auto;
}
.tour-detail-banner .carousel-slider {
  border-radius: 20px;
}
.tour-detail-banner .carousel-image {
  height: 350px;
  background-size: cover !important;
  background-position: center !important;
}
.tour-detail-bottom {
  margin: 0 auto 300px auto;
  width: 80%;
  max-width: 1080px;
  border: #BABABA solid 1px;
  border-radius: 20px;
  padding: 30px;
}
.tour-detail-bottom .title {
  font-size: 1.3em;
  color: #2E5370;
  font-weight: 500;
}
.tour-detail-bottom .highlight {
  color: #1D1D1D;
  margin: 20px 0 50px 0;
}
.tour-detail-bottom .main-content .menu {
  overflow: auto;
  position: relative;
}
.tour-detail-bottom .main-content .menu .active {
  color: #4B4B4B !important;
  font-weight: 500;
  border-bottom: 3px solid #2E5370 !important;
  padding-top: 0 !important;
  cursor: unset;
}
.tour-detail-bottom .main-content .menu .menu-item {
  padding-top: 1px;
  font-size: 1.25em;
  color: #959595;
  float: left;
  cursor: pointer;
}
.tour-detail-bottom .main-content .menu .menu-item:not(:last-child) {
  margin-right: 20px;
}
.tour-detail-bottom .main-content .menu:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #B1B1B1;
  display: block;
  position: absolute;
  bottom: 0;
}
.tour-detail-bottom .main-content [class*="-block"] {
  padding: 30px 10px;
}
.tour-detail-bottom .main-content .price-include,
.tour-detail-bottom .main-content .price-not-include {
  font-size: 1.2em;
  color: #2E5370;
  margin-bottom: 30px;
}
.tour-detail-bottom .main-content .price-include i,
.tour-detail-bottom .main-content .price-not-include i {
  margin-right: 5px;
}
.tour-detail-bottom .main-content .price-include .text,
.tour-detail-bottom .main-content .price-not-include .text {
  font-size: 0.85em;
  color: #555555;
  margin-top: 5px;
}
.display-none {
  display: none;
}
img {
  max-width: 100%;
  height: auto;
}
.table {
  overflow: auto;
}
@media (max-width: 550px) {
  .tour-detail-banner {
    width: 95%;
    margin: 10px auto 10px auto;
  }
  .tour-detail-bottom {
    width: 95%;
    padding: 30px 10px;
    margin-bottom: 50px;
  }
}

.About {
  width: 1000px;
  margin: 100px auto;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 80px 30px 120px 30px;
}
.About .title {
  text-align: center;
  display: inline-block;
  font-size: 1.8em;
  margin-bottom: 50px;
}
.About .title:after {
  content: "";
  display: block;
  width: 120px;
  height: 3px;
  background-color: #204865;
}
.About p {
  color: #4d4d4d;
}
@media (max-width: 550px) {
  .About {
    width: 95%;
    margin: 10px auto;
  }
}

