@major-black: #232323;
@done-green: #9ece44;
@major-blue: #f0f5f8;
#step-frame {
    display: flex;
    flex-direction: row;
    justify-content: space-around;    
    padding: 70px 10px;
    
    max-width: 900px;
    width: 70%;
    margin: 0 auto;
    .step {        
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        .step-t {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 15px;
            .step-number {
                border: 3px #e6e6e6 solid;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #e6e6e6;
                border-radius: 30px;
                font-size: 1.1em;
                font-weight: bold;

            }
            .step-line {
                flex: 1;
                height: 4px;
                background-color: #e6e6e6;
                
            }
            .step-line-none {

                flex: 1;
            }

        }
        .step-tip {
            color: #cecece;
            font-weight: 400;
            padding: 0 10px;

        }
    }
    .now-step {
        .step-number {
            border-color: @major-black !important;
            color: white !important;
            background-color: @major-black;

        }
        .step-line:first-child {
            background-color: @major-black; 
        }
        .step-tip {
            color: @major-black;
            font-weight: bold;

        }
    }
    .final-step {
        .step-number {
            border-color: #1ab2e1 !important;
            color: white !important;
            background-color: #1ab2e1 !important;

        }
        .step-line:first-child {
            background-color: #1ab2e1 !important; 
        }
        .step-tip {
            color: #1ab2e1 !important;
            font-weight: bold !important;

        }

    }
    .step-done {
        .step-t {
            .step-line {
                background-color: @done-green;
            }
            .step-line:last-child {
                background-color: @done-green;
            }
            .step-number {
                border-color: @done-green;
                color: white;
                background-color: @done-green;
                
            }

        }

    }
}
@media (max-width:800px){
    #step-frame {
        padding: 70px 10% 50px 10%;
        width: 90%;
        padding: 40px 0;
    }
}
@media (max-width:500px){
    #step-frame {
        display: none;
    }
}