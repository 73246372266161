#small-step-main {
    width: 95%;
    margin: 0px auto;
    position: relative;
    display: none;
    padding: 16px 3px;
    flex-direction: column;
    #small-step-num {
        display: flex;
        align-items: center;
        .small-step-line {
            flex: 1;
            height: 2px;
            background-color: #8c8c8c;
        }
        #circle {
            border-radius: 100px;
            height: 40px;
            width: 40px;
            background-color: #4c4c4c;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            font-size: 1.1em;
            margin: 8px;
            color: white;
            
        }
    }
    
    #small-step-text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        color: #8c8c8c;
        margin: 5px 0;
    }

}
@media (max-width:500px){
    #small-step-main {
        display: flex;
    }
}