@major-black: #232323;
@done-green: #9ece44;
@major-blue: #f0f5f8;
#tags-pick {
    margin: 15px auto 5px auto;
    display: none;
    flex-wrap: wrap;
    .tags-pick-tag {        
        background-color: transparent;
        border-radius: 5px;
        padding: 5px 10px;
        margin-right: 5px !important;
        border: 1px solid;
        font-size: 14px;
        white-space: nowrap;
        margin: 3px 0;

    }
    .tags-pick-tag:hover {
        color: rgba(0,0,0,.6);
        border-color: rgba(0,0,0,.6);
        cursor: pointer;

    }

}
#tour-list {        
    overflow: auto;
    .tour-item {
        padding: 15px;
        border-bottom: 1px solid #c0c0c0;
        display: flex;
        .tour-info-l {
            width: 40%;
            height: 180px;
            display: flex;
            .tour-days {    
                width: 40px;
                background-color: #474747;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                color: white;
                margin-right: 5px;
                font-weight: 400;
                p:first-child {
                    font-size: 20px;
                }
                p{
                    font-size: 10px;
                }
            }
            .tour-photo{
                flex: 1;
                overflow: hidden;
                position: relative;
                background-size: cover !important;
                .tour-price {        
                    position: absolute;
                    bottom: 0;
                    background-color: rgba(35, 35, 35, 0.5);
                    width: 100%;
                    color: white;
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                    font-size: 20px;

                }
            }

        }
        .tour-info-r {
            position: relative;    
            width: 60%;
            padding-left: 10px;
            .small-info {     
                display: none;
                position: absolute;
                bottom: 0;
                right: 0;
                .days {
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    font-size: 0.9em;
                    padding: 3px 5px;
                    color: #aaa;
                    border: 1px #aaa solid;
                    background-color: transparent;
                    margin: 3px;

                }
                .price {    
                    margin-left: 5px;
                    color: white;
                    align-items: center;
                    background-color: #707070;
                    border-radius: 10px;
                    font-size: 0.9em;
                    padding: 3px 5px;
                    margin: 3px;

                }
            }
            .title {    
                margin-top: 10px;
                font-weight: 600;
                font-size: 1.2em;
            }
            .intro {
                margin-top: 10px;
                font-weight: 300;
                font-size: 0.9em;
                position: relative;
                line-height: 1.5em;
                height: 6em;
                overflow: hidden;
                color: #717373;

            }
            .tour-item-tags {
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                flex-wrap: wrap;
                .tour-tag {    
                    background-color: transparent;
                    border-radius: 15px;
                    padding: 5px 10px;
                    margin-left: 5px;
                    border: 1px solid #adadad;
                    color: #999999;
                    font-size: 12px;
                    font-weight: 300;
                    white-space: nowrap;
                    margin-top: 3px;
                }
            }
        }
    }
    .tour-item:hover {
        opacity : 0.7;
        cursor: pointer;
    }
}


@media (max-width:500px){
    #tour-list .tour-item .tour-info-r .tour-item-tags {
        display: none;
    }
    #tour-list .tour-item .tour-info-l .tour-photo .tour-price {
        display: none;
    }
    #tour-list .tour-item .tour-info-l .tour-days {
        display: none;
    }
    #tour-list .tour-item .tour-info-l {
        height: 100px !important;
    }
    #tour-list .tour-item .tour-info-l .tour-photo img {
        height: 100px;
    }
    #tour-list .tour-item {
        padding: 3px;
    }
    #tour-list .tour-item .tour-info-r .small-info {
        display: flex;
    }
}
@media (max-width:800px){
    #tour-list .tour-item .tour-info-l {
        height: 130px;
    }
    #tour-list .tour-item .tour-info-r .title {
        margin-top: 5px;
        font-size: 1.1em;
    }
    #tour-list .tour-item .tour-info-r .intro {
        height: 4.5em;
    }
    #tour-list .tour-item .tour-info-l .tour-photo img {

    }
}
