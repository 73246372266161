@major-black: #232323;
@done-green: #9ece44;
@major-blue: #f0f5f8;

#date-block .flatpickr-time {
    display: none;
}
#step1content {
    width: 90%;
    margin: 0 auto;
}
.block-t,.block-b {    
    border-radius: 5px;
    margin: 30px auto 20px auto;
    padding-bottom: 0px;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 10px;
    box-shadow: 1px 1px 5px rgba(0,0,0,.3);
    .basic-info-title {
        width: 50%;
        padding: 8px 30px;
        box-sizing: border-box;
        color: white;
        background-color: #426784;
        border-radius: 30px;
        border: solid 2px #426784;
        text-align: center;
    }
    .basic-info-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 70px 60px 70px;
        #content-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 95%;
            #date-block {    
                display: flex;
                font-weight: 500;
                background-color: #324f71;
                width: 450px;
                margin-right: 20px;
                .default {
                    padding: 7px 6px;
                    background-color: white;
                    width: 100%;
                    text-align: center;
                    border: 1px solid #426784;
                }
                .date-icon {
                    display: flex;
                    align-items: center;
                    margin: 0 9px 0 10px;
                    color: white;
                    .fas {
                    }
                    
                }
        
            }
            .check-block {    
                display: flex;
                width: auto;
                justify-content: space-between;
                font-size: 0.9em;
                .item-box {
                    background-color: #e6edf3;
                    width: 120px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    margin: 0 5px;
                    .container {
                        width: unset;
                        padding-right: unset;
                        display: block;
                        position: relative;
                        padding-left: 23px;
                        margin-bottom: unset;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        height: 16px;
                        line-height: 16px;
                        color: #2a4156;
                        font-weight: 400;
                        
                    }
                      
                      /* Hide the browser's default checkbox */
                    .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                    }
                    
                    /* Create a custom checkbox */
                    .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 16px;
                    width: 16px;
                    background-color: white;
                    }
                    
                    /* On mouse-over, add a grey background color */
                    .container:hover input ~ .checkmark {
                    background-color: #ccc;
                    }
                    
                    /* When the checkbox is checked, add a blue background */
                    .container input:checked ~ .checkmark {
                    background-color: #c42b30;
                    }
                    
                    /* Create the checkmark/indicator (hidden when not checked) */
                    .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                    }
                    
                    /* Show the checkmark when checked */
                    .container input:checked ~ .checkmark:after {
                    display: block;
                    }
                    
                    /* Style the checkmark/indicator */
                    .container .checkmark:after {
                    left: 5px;
                    top: 3px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    }
                }
            }
        }
        
    }
    
    
}
@media (max-width:1200px){
    #step1content {
        width: unset;
    }
}
@media (max-width:700px) {
    .block-t .basic-info-content #content-inner {
        flex-direction: column;
        margin: 0;
        width: 70%;
        align-items: flex-start;
    }
    .block-t .basic-info-content #content-inner #date-block {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .block-t .basic-info-content #content-inner .check-block .item-box:first-child {
        margin: 0 10px 0 0;
        
    }
}
@media (max-width:500px) {
    .block-t .basic-info-content {
        background-color: unset;
    }
    .block-t .basic-info-content #content-inner {
        flex-direction: column;
        margin: 0;
        width: 90%;
        align-items: flex-start;
    }
    #step1content {
        width: 100%;
        background-color: white;
        padding: 5px 0;
        margin-top: 20px;
        border-radius: 5px;
    }
    .row {
        display: flex;
        flex-direction: column;
    }
    .block-t{
        border: unset !important;
        height: unset;
        margin: 20px auto;
    }
    .basic-info-title {
        display: none !important; 
    }
    .block-t .basic-info-content #content-inner #date-block {
        background-color: #727272;
        color: #5e5e5e;
        border: unset;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .block-t .basic-info-content #content-inner .check-block {
        width: 100%;
        padding-bottom: 20px;
        
    }
    .block-t .basic-info-content #content-inner #date-block .fas {
        color: white;
    }
    .block-t .basic-info-content #content-inner .check-block .item-box {
        background-color: white;
        color: #7c7c7c;
        height: 35px;
        width: 100px;
        flex: 1 1;
        border-radius: 0px;
        box-shadow: 1px 1px 10px #eaeaea;
    }
    .block-t .basic-info-content #content-inner .check-block .item-box .container {
        padding-left: 29px;
        
    }
    .block-t .basic-info-content #content-inner .check-block .item-box .checkmark {
        background-color: white;
        border: 1px solid #bbb;
        
    }
}