.tourDisplay {
    margin: 0 auto;
    width: 1000px;
}
.block {
    width: 100%;
    .menu-block {
        position: relative;
        border-bottom: 2px solid rgba(51, 94, 124, 0.7);
        margin: 50px 0 30px 0;
        
        span {
            background-color: #335D7C;
            display: inline-block;
            padding: 6px 14px 3px 14px;
            border-radius: 5px 5px 0 0;
        
            p {
                font-size: 1.2em;
                margin: unset;
                color: white;
                text-align: center;
            }
        }
        .subMenu-block {
            position: absolute;
            right: 0;
            bottom: 0;
            padding-bottom: 5px;
            .subMenu-item {
                display: inline-block;
                font-size: 1.2em;
                color: #334c5fba;
                margin-left: 10px;
                padding: 2px 10px;
                &.active {
                    border: 1px solid #375369;
                    background-color: #c2d6e659;
                    border-radius: 20px;
                    color: #375369;
                    font-weight: 500;
                
                }
            }
        }
    }
    .tour-block {
        display: flex;
        flex-wrap: wrap;
        .tour-item {
            height: 257px;
            width: 230px;
            border: 1px #D5D5D5 solid;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 30px;
            .tour-item-img {
                width: 100%;
                height: 160px;
                background-size: cover !important;
            }
            .tour-item-title {    
                margin: 10px;
                height: 45px;
                color: #004B83;
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
            .tour-item-day {
                padding: 0 10px;
                text-align: right;
                i {
                    color: #454545;
                    span {
                        padding-left: 3px;
                        font-size: .8em;
                    }
                    &:before {
                        margin-right: 5px;
                    }
                }
            }
            &:hover {
                box-shadow: 1px 1px 10px rgba(0,0,0,.2);
                transform: translateY(-2px);
                
            }
        }
        a {
            &:not(:nth-child(4n)) .tour-item{
                margin-right: 26px;
            }
        }
    }
}
@media (max-width:550px) {
    .tourDisplay {
        width: 100%;
    }
    .block {
        .tour-block {
            a {
                width: calc( 50% - 10px );
                margin: 0 5px 10px 5px;
                .tour-item {
                    width: 100%;
                    height: 245px;
                    margin-bottom: 5px;
                    .tour-item-title {   
                        height: 37px;
                    }
                }
            }
            a {
                &:not(:nth-child(4n)) .tour-item{
                    margin-right: unset;
                }
                &:not(:nth-child(2n)) .tour-item{
                    margin-right: 5px;
                }
            }
        }
    }
}