@major-black: #232323;
@done-green: #9ece44;
@major-blue: #f0f5f8;
#undecided-picked-tour-list {    
    background-color: @major-blue;
    padding: 15px 35px;   
    font-size: 0.9em;
    .u-p-t-l-row {
        display: flex;
        background-color: white;
        border-radius: 5px;
        padding: 10px 20px;
        margin: 5px 0;
        .u-p-t-l-row-info {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            .u-p-t-l-title {
                flex: 1;
    
            }
            .u-p-t-l-row-subinfo {
                display: flex;
                .u-p-t-l-days {
                    min-width: 60px;
                    text-align: center;
                    color: #5f6368;
                    font-size: 0.9em;
                    .fas {
                        margin-right: 5px;
                    }
        
                }
                .u-p-t-l-price {
                    min-width: 85px;
                    text-align: center;
                    color: #5f6368;
                    font-size: 0.9em;
                    
                }
            }
            
        }
        .u-p-t-l-delete {
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            .fas {
                color: #d0d5d9;
            }
            
        }
        
        .u-p-t-l-delete:hover {
            cursor: pointer;
            .fas {
                color: red;
            }
        }
    }
    #u-p-t-l-result {
        text-align: right;
        margin-top: 15px;
        
    }
}

@media (max-width:500px){
    #undecided-picked-tour-list {
        padding: 10px 15px;
    }
    .u-p-t-l-row-info {
        flex-direction: column;
    }
    #undecided-picked-tour-list .u-p-t-l-row .u-p-t-l-row-info .u-p-t-l-row-subinfo {
        margin-top: 5px;
    }
    #tour-list .tour-item .tour-info-r .intro {
        display: none;
    }
}