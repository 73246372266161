@major-black: #232323;
@done-green: #9ece44;
@major-blue: #f0f5f8;
body {    
    letter-spacing: 1px;
    margin: 0;
    font-size: medium;
    font-family: 'Noto Sans TC', sans-serif;
    p {
        margin: unset;
    }

    a {
        text-decoration: none !important;
        color: unset;
    }
}

#content {
    border-radius: 10px;
    min-height:75vh;
    margin: 0 auto;
    #content-major {
        width: 70%;
        max-width: 1080px;
        margin: 0 auto;
    }
}

@media (max-width:900px){
    #content {
        #content-major {
            width: 90%;
        }
    }
    body {
        font-size: 14px;
    }
}
@media (max-width:450px){
    body {
        font-size: small;
    }
    #content {
        #content-major {
            width: 95%;
        }
    }
}
