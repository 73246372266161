#picked-tour-list {
    border: solid 1px #ccc;
    .picked-tour {
        display: flex;
        border-bottom: 1px solid #ccc;
        .picked-tour-l {   
            display: flex;
            align-items: center;
            padding: 15px 0px 15px 25px;
            flex: 1;
            .picked-tour-img {
                width: 80px;
                height: 80px;
                background-size: cover !important;
                border-radius: 50px;
                margin-right: 20px;
                border: solid white 2px;
                box-shadow: 0px 0px 7px #888888;
                img {
                    width: 130px;
                }
            }
            .picked-tour-title {
                flex: 1;    
                display: flex;
                align-items: center;

            }
            

        }
        .picked-tour-r {
            padding: 3px;
            display: flex;
            align-items: center;
            .tour-pick-day {
                border-color: #CCC;
                border-radius: 20px;
                padding: 8px;
                -webkit-appearance: none;
                text-align-last: center;    
                width: 110px;
                margin: 5px;
                background-color: white;


            }
            .picked-tour-price{
                font-weight: bold;
                color: #297294;
                
                display: flex;
                align-items: center;    
                width: 80px;
                margin: 5px;
                justify-content: center;
            }
            .picked-tour-r-btn {    
                width: 50px;
                color: white;
                height: 100%;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                margin: 0 2px;

            }
            
            .picked-tour-days {
                background-color: black;
                background-color: #474747;
                p:last-child {
                    font-size: 0.8em;
                }
            }
            .picked-tour-delete-btn {
                background-color: #c9655e;
            }
            .picked-tour-delete-btn:hover {
                background-color: red;
                cursor: pointer;
            }
            
        }
    }
}

@media (max-width:1024px){
    #picked-tour-list .picked-tour .picked-tour-l {
        padding: 15px 25px 15px 25px;
    }
    #picked-tour-list .picked-tour .picked-tour-l .picked-tour-price {
        margin: 10px;
    }
    #picked-tour-list .picked-tour {
        flex-direction: column;
    }
    #picked-tour-list .picked-tour .picked-tour-l,#picked-tour-list .picked-tour .picked-tour-r {
        width: unset;
        align-items: unset;
        border-top: 1px solid #ccc;
        
    }
    #picked-tour-list .picked-tour .picked-tour-r {
        padding: 5px;

    }
    #picked-tour-list .picked-tour .picked-tour-r .tour-pick-day,#picked-tour-list .picked-tour .picked-tour-r .picked-tour-price {
        width: 35%;
        margin: unset;
    }
    #picked-tour-list .picked-tour .picked-tour-r .picked-tour-price {
        justify-content: flex-end;
        margin-right: 10px;
    }

    
    #picked-tour-list .picked-tour .picked-tour-r .picked-tour-r-btn,#picked-tour-list .picked-tour .picked-tour-r .picked-tour-r-btn {
        width: 15%;
        flex-direction: unset;
        flex: unset;
        height: unset;
    }
}

@media (max-width:500px) {
    #picked-tour-list .picked-tour .picked-tour-l {
        padding: 10px 10px;
    }
    #picked-tour-list .picked-tour .picked-tour-l .picked-tour-img {
        margin-right: 20px;
    }
}