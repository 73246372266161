.day-tour-block {
    width: 1000px;
    height: 500px;
    margin: 50px auto;
    color: white;
    position: relative;
    .day-tour-center  {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-70%);
        .day-tour-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            h1 {
                font-size: 100px;
                color: rgba(255,255,255,.9);
                text-shadow: 2px 2px 6px rgba(0,0,0,.4);
                font-family: "Caveat";
            }
            p {
                font-size: 25px;
                margin: 10px 0 20px 0;
                text-shadow: 2px 2px 6px rgba(0,0,0,.4);
                font-weight: 300;
            }
            .day-tour-btn {
                width: 230px;
                height: 63px;
                background-color: #2E5370;
                box-shadow: 3px 3px 8px rgba(0,0,0,.6);
                text-shadow: 2px 2px 6px rgba(0,0,0,.4);
                color: rgba(255,255,255,.9);
                font-size: 1.5em;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 3px;
                color: #dce3e9;
                transition: background-color 500ms;
            }
            .day-tour-btn:hover {
                background-color: #4a83a1;
                color: white;
                cursor: pointer;
            }
        }

    }
    .external {
        position: absolute;
        display: flex;
        align-items: center;
        height: 80px;
        bottom: 0;
        right: 0;
        color: rgba(255,255,255,.7);
        padding: 0 20px;
        p {
            margin-right: 20px;
        }
        .external-btn {
            border: 2px solid rgba(255,255,255,.7);
            border-radius: 5px;
            padding: 8px 10px 8px 10px;
            width: 130px;
            i {
                margin-top: 4px;
                margin-left: 5px;
                transition: margin-left 500ms;
            }
            img {
                width: 80px;
            }
        }
        .external-btn:hover i {
            margin-left: 11px;
            color: rgba(255,255,255,.9);
        }
        .external-btn:hover {
            border: 2px solid rgba(255,255,255,.9);
            cursor: pointer;
        }
    }
}
@media (max-width:500px) {
    .day-tour-block {
        width: 100%;
        .day-tour-center  {
            .day-tour-text {
                h1 {
                    font-size: 5em;
                }
            }
    
        }
    }
}