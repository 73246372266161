@major-black: #232323;
@done-green: #9ece44;
@major-blue: #f0f5f8;
.titleRequired:before{
    content:"*";
}
.required.error{
    border: 1px solid red !important;
}
.bill-block {
    border: 1px solid #CCC;
    margin-top: 25px;
    padding: 0 35px;
    .bill-inner-title {    
        border-bottom: 1px solid #c2c2c2;
        padding: 20px 0 1px 3px;
        font-size: 1.1em
    }
    #notice {    
        line-height: 26px;
        font-size: 0.9em;
        font-weight: 300;
    }
    .bill-inner {
        margin: 20px auto;
        width: 87%;
        #list {
            color: #4d3a3d;
            text-align: center;
            .list-row {
                display: flex;
                padding: 5px 0;
                border-bottom: 1px solid #c5c1b5; 
                .list-cell:nth-child(2) {
                    flex: 1;
                }
                .list-cell {
                    width: 10%;
                }
            }
            .list-row:not(:first-child) {
                .list-cell:nth-child(2) {
                    text-align: left;
                }
            }
            .list-row:first-child {
                color: @major-black;
            }
        }
        .form-row {    
            display: flex;
            margin: 15px 0;
            justify-content: space-between;
            .row-item {
                line-height: 30px;
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: flex-start;
                // .nationInfo {
                //     display: flex;
                    
                //     #basicNation{
                //         width: 30%;
                //     }
                //     .nationality{
                //     margin-right: 10px;
                //     }
                // }
                .phone{
                    display: flex;
                    .code {
                        margin-right: 10px;
                        color: #3970ca;
                        font-size: 0.8em;

                    }
                }

                .item-tip {
                    color: #3970ca;
                    font-size: 0.8em;
                }
                #communication {
                    display: flex;
                    select {
                        margin-right: 10px;
                    }
                }
                
            }
            .row-item:not(:last-child) {
                padding-right: 20px
            }
            nobr {
                margin-right: 10px;
                font-size: 0.9em;
            }
            select {
                background-color: white;
                height: 35px;
                border: solid 1px #CCC;

            }
            input {
                border-radius: 5px;
                border: solid 1px #CCC;
                width: 100%;
            }
            textarea {
                border-radius: 5px;
                border: solid 1px #CCC;
                width: 100%;
            }
            form {
                display: flex;
                .radio-item {
                    flex:1;
                    display: flex;
                }
                input {
                    width: unset;
                }
            }
            .hint {
                width:100%;
                text-align: center;
                background: #f8f4ef;
                padding: 50px 20px;
            }
            .row-btn {
                width: 45%;
                text-align: center;
                border-radius: 10px;
                border: 1px solid lightgray;
                padding: 30px 0;
                cursor:pointer;
            }
            .row-btn:hover {
                background-color: #f8f4ef;
                color:black;
            }
            .row-btn:focus {
                background: #ffb355;
                color: white;
            }
            .row-btn.disabled {
                background: gray;
            }
            .center{
                margin: 0 auto;
            }
            
        }
    }
    #bill-total-price {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;    
        margin-top: 5px;
        p {
            color: chocolate;

        }
        
    }
}

.bill-block.hide{
    display: none;
}

@media (max-width:750px) {
    .form-row.btn-row {
        flex-direction: row;        
        .row-btn {
            width: 44% !important;
        }    
    }
    .form-row {
        padding-top: 5px;
        flex-direction: column;        
        .row-btn {
            width: 100% !important;
        }       
    }    
    .bill-block {
        padding: 0 10px;
    }
    .bill-block .bill-inner .form-row .row-item {
        margin: 5px 0;
    }
    .row-item:not(:last-child) {
        padding-right: 0px !important;
    }
}
@media (max-width:500px) {
    .bill-block .bill-inner {
        width: 100%;
    }
}