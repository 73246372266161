.footer {
    //position: absolute;
    padding: 50px 0;
    width: 100%;
    bottom: 0;
    background-color: #204865;
    color: #BDD3E3;
    font-size: 0.85em;
    display: flex;
    align-items: center;
    .footer-content {
        margin: 0 auto;
        width: 1000px;
        display: flex;
        //align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
}
.footer-logo {
    width: 25%;
    background-size:contain;
    background-position:center;
    background-repeat: no-repeat;    
    img {
        height: 80px;
    }

}
.footer-text1 {
    width: 35%;

}
.footer-text2 {
    width: 30%;

}
.footer-pay {
    width: 10%;
    img {
        margin-top: 10px;
    }

}
@media (max-width: 550px){
    .footer {
        .footer-content {
            flex-direction: column;
            width: unset;
        }
    }
    .footer-logo,.footer-text1,.footer-text2,.footer-pay {
        width: 100%;
        padding-bottom: 20px;
    
    }
}