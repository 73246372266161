@major-black: #232323;
@done-green: #9ece44;
@major-blue: #f0f5f8;
[aria-expanded="false"] .menu__icon--open {
    display: inline;
  }
  
  [aria-expanded="false"] .menu__icon--close {
    display: none;
  }
  
  [aria-expanded="true"] .menu__icon--open {
    display: none;
  }
  
  [aria-expanded="true"] .menu__icon--close {
    display: inline;
  }
  .menu__icon--close {
      display: none;
  }

#accordion {
    width: 100%;
    border: unset;
    .panel {
        box-shadow: 1px 1px 5px #ccc;
        border: unset;
        .panel-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            color: #484e51;    
            background-color: white;
            border: unset;
            #accordion-title-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                
                #title-name {
                    font-weight: bold;
                    flex: 1;
                }
                #title-tip {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    #tip-text {
                        font-size: 0.9em;
                        color: #666666;
                        margin-right: 10px;
                        
                    }
                    .fa {
                        margin-left: 5px;
                        text-align: center;
                    }
                    #price-block {    
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        background-color: rgba(255,255,255,.4);
                        border-radius: 20px;
                        padding: 5px 10px;
                        color: #1f3c80;
                        .fas {
                
                        }
                        #price {
                            margin: 0 3px 0 8px;
                
                        }
                    }
                }
            }
            #accordion-title-sign {

                text-align: center;
            }
            
        }
    }
    .panel:hover {
        box-shadow: 1px 1px 5px #aaa;
    }
}
#customized-list {
    height: 100%;
    padding: 20px 25px;
    background-color: #f0f5f8;    
    #customized-list-inner {
        position: relative;    
        z-index: 0;
        .customized-list-item-outer {
            position: relative;
            .customized-list-item {
                display: flex;
                padding: 10px;
                .c-l-i-date {        
                    width: 70px;
                    height: 70px;
                    overflow: hidden;
                    border-radius: 100px;
                    background-color: #c5d7e2;
                    border: 2px solid #c5d7e2;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    color: white;
                    p:first-child {
                        font-size: 1.1em;
                    }
                    p:last-child {
                        font-size: 0.9em;
                        
                    }
                

                }
                .c-l-i-content {    
                    font-size: 0.9em;
                    color: #707070;
                    flex: 1;
                    margin: 10px 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;    
                    line-height: 26px;
                    .c-l-i-content-inner {    
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .c-l-i-content-item {
                            background-color: white;
                            display: flex;
                            justify-content: space-between;
                            padding: 5px 15px;
                            height: 100%;
                            align-items: center;
                            .title {
                                display: flex;
                                width: 75%;
                                line-height: 20px;
                                
                            }
                            .sub {
                                width:25%;
                                display: flex;
                                align-items: center;
                                .c-l-i-d {
                                    flex: 1;
                                    text-align: center;
                                    color: #4C7D9B;
                                    .fas {
                                        margin-right: 5px;
                                    }
                                    
                                }
                                .c-l-i-p {
                                    flex: 1;
                                    text-align: center;
                                    color: #983436;
        
                                }
                            }
        
                        }
                        .repeat {
                            background-color: #e9ecf1;
                            .title {
                                color: #8a8c8c;
                                font-weight: 300;
                            }
                            .sub {
                                .c-l-i-d {
                                    color: #8a8c8c;
                                    font-weight: 300;
                                    
                                }
                                .c-l-i-p {
                                    color: #8a8c8c;
                                    font-weight: 300;
        
                                }
                            }
                        }
                        .c-l-i-content-item:not(:last-child) {
                            border-bottom: 1px solid #CCC;
                        }
                        .c-l-i-content-item:first-child {
                            border-top-left-radius: 7px;
                            border-top-right-radius: 7px;
                        }
                        .c-l-i-content-item:last-child {
                            border-bottom-left-radius: 7px;
                            border-bottom-right-radius: 7px;
                        }
                    }
                    

                }
            }
            
            .c-l-i-empty {
                .c-l-i-date {        
                    background-color: #f0f5f8;
                    color: #c5d7e2;

                }
                .c-l-i-content {    
                    background-color: #f0f5f8;
                    border: 2px dashed #c5d7e2;
                    border-radius: 7px;
                    box-sizing: border-box;
                    color: #c5d7e2;
                }
            }
        }
        .customized-list-item-outer:first-child .first-child-note {
            display: block !important;
            color: #b44c4a;
            font-size: 0.8em;
            line-height: 16px;
            i {
                margin-right: 5px;
            }
        }
        .customized-list-item-outer:last-child .last-child-note {
            display: block !important;
            color: #b44c4a;
            font-size: 0.8em;
            line-height: 16px;
            i {
                margin-right: 5px;
            }
        }
        

    }
    
}
.customized-list-item-outer:not(:last-child) #customized-list-line {
    background-color: #c5d7e2;
    width: 5px;
    height: 100%;
    position: absolute;
    top: 10px;
    left: 43px;
    z-index: -1;
}
#air-wrong {    
    border: 1px solid #c45250;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px 20px;
    font-size: 0.9em;
    color: #c45250;
    i {
        margin-right: 5px;
    }
}
@media (max-width:1024px){
    #customized-list {
        padding: 20px 15px;
    }
    #customized-list #customized-list-inner .customized-list-item .c-l-i-date {
        width: 80px;
        height: 80px;
    }
    #customized-list #customized-list-inner .customized-list-item .c-l-i-date .two-tours {
        height: 40px;
    }
    #customized-list #customized-list-inner .customized-list-item-outer:not(:last-child) #customized-list-line {
        left: 43px;
        width: 4px;
    }
    #customized-list #customized-list-inner .customized-list-item .c-l-i-content {
        line-height: 20px;
        margin: 10px 12px;
    }
    #customized-list #customized-list-inner .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .title {
        width: 70%;
    }
    #customized-list #customized-list-inner .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub {
        width: 30%;
    }
}

@media (max-width:750px)  {
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .title {
        width: 65%;
        line-height: 18px;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub {
        width: 35%;
    }
    #accordion .panel .panel-heading {
        flex-direction: column;
        align-items: unset;
    }
    #accordion .panel .panel-heading #title-tip .fa {
        width: 100%;
    }
    #accordion .panel .panel-heading #accordion-title-sign {
        margin-top: 10px;
    }
    #accordion .panel .panel-heading #accordion-title-text #title-tip #price-block {
        margin: 0 0 0 10px;
        padding: 5px 0;
    }
    

}
@media (max-width:500px) {
    #accordion .panel .panel-heading #accordion-title-text #title-tip #price-block {
        padding: 0;
        margin: 0 0 0 10px;
        
    }
    #accordion .panel .panel-heading {
        padding: 8px 12px;

    }
    #customized-list {
        padding: 3px;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item {
        padding: 6px;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content {
        margin: 0 7px;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-date {
        width: 60px;
        height: 60px;
        border: 2px solid #6a9cba;
        color: #4a7f9f;
        background-color: #f0f5f8;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-date p:last-child {
        padding-top: 3px;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .c-l-i-empty .c-l-i-date {
        color: #a5bbc7;
    }
    #customized-list #customized-list-inner .customized-list-item-outer:not(:last-child) #customized-list-line {
        left: 34px;
        width: 3px;
    }
    .c-l-i-empty .c-l-i-date,.c-l-i-empty .c-l-i-content {
        border: 1px dashed #c5d7e2 !important;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item {
        flex-direction: column;
        padding: 8px;
        align-items: unset;
        line-height: 20px;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .title {    
        width: unset;
        line-height: 17px;
        margin-bottom: 3px;
        font-size: 1.1em;
    }
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub {
        width: unset;
        justify-content: flex-end;
    }

    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub .c-l-i-d,
    #customized-list #customized-list-inner .customized-list-item-outer .customized-list-item .c-l-i-content .c-l-i-content-inner .c-l-i-content-item .sub .c-l-i-p {
        flex: unset;
        width: 50px;
    }
    .c-l-i-date {
        line-height: 13px;
    }

}
