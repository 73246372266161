
.tour-detail-banner {
    width: 80%;
    max-width:1080px;
    margin: 0 auto;
    margin: 50px auto 40px auto;
    .carousel-slider {
        border-radius: 20px;
        
    }
    .carousel-image {
        height: 350px;
        background-size: cover !important;    
        background-position: center !important;
    }
}
.tour-detail-bottom {    
    margin: 0 auto 300px auto;
    width: 80%;
    max-width: 1080px;
    border:#BABABA solid 1px;
    border-radius: 20px;
    padding: 30px;
    .title {
        font-size: 1.3em;
        color: #2E5370;
        font-weight: 500; 

    }
    .highlight {
        color: #1D1D1D;
        margin: 20px 0 50px 0;
        
    }
    .main-content {
        .menu {
            //border-bottom: 1px solid #B1B1B1;
            overflow: auto;
            position: relative;
            .active {
                color: #4B4B4B !important;
                font-weight: 500;
                border-bottom: 3px solid #2E5370 !important;
                padding-top: 0 !important;
                cursor: unset;
            }
            .menu-item {
                padding-top: 1px;
                font-size: 1.25em;
                color: #959595;
                float: left;
                cursor: pointer;
                
                &:not(:last-child){
                    margin-right: 20px;
                }
            }
            &:after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: #B1B1B1;
                display: block;
                position: absolute;
                bottom: 0;
            }
        }
        [class*="-block"] {
            padding: 30px 10px;
        }
        .price-include,.price-not-include {
            font-size: 1.2em;
            color: #2E5370;
            margin-bottom: 30px;
            i {
                margin-right: 5px;

            }
            .text {
                font-size: 0.85em;
                color: #555555;
                margin-top: 5px;

            }

        }
    }
}
.display-none {
    display: none;
}
img {
    max-width: 100%;
    height: auto;
}
.table {
    overflow: auto;
}
@media (max-width: 550px) {
    .tour-detail-banner {
        width: 95%;
        margin: 10px auto 10px auto;
    }
    .tour-detail-bottom {
        width: 95%;
        padding: 30px 10px;
        margin-bottom: 50px;
    }
}