@import '../../Basic.css';
#num-block {
  display: flex;
  width: 100%;
  flex-direction: column;
}
#num-block .row {
  display: flex;
  margin: unset;
}
#num-block .row .row-item {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
#num-block .row .row-item .title {
  display: flex;
  align-items: center;
  flex: 1 1;
  justify-content: flex-start;
  color: #2a4156;
}
#num-block .row .row-item .title span {
  font-size: 0.8em;
  margin-left: 5px;
  color: #2a4156;
}
#num-block .row .row-item .select-num {
  width: 100px;
  height: 40px;
  background-color: #e6edf3;
  border-radius: 100px !important;
  border: unset;
  color: #2a4156;
  -webkit-border-radius: 0px;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
  flex: 1 1;
}
#num-block .row .row-item:first-child {
  margin-right: 110px;
}
#num-block .row:first-child {
  margin-bottom: 15px;
}
#num-block .small-notice {
  display: none;
  padding: 10px 0;
}
#num-block input {
  width: 70px;
  margin: 0 8px;
}
@media (max-width: 1100px) {
  .block-b .basic-info-title {
    flex-direction: column;
  }
  .block-b .basic-info-content #num-block .row .row-item:first-child {
    margin-right: 40px;
  }
}
@media (max-width: 1000px) and (min-width: 900px) {
  .block-b .basic-info-content #num-block .row .row-item .title {
    flex-direction: column;
    align-items: baseline;
  }
}
@media (max-width: 800px) {
  #num-block {
    width: 75%;
  }
  .block-b .basic-info-content #num-block .row .row-item .title {
    flex: unset;
  }
  .block-b .basic-info-content #num-block .row .row-item .select-num {
    flex: unset;
    width: 100px;
  }
  .block-b .basic-info-content #num-block .row .row-item {
    justify-content: space-between;
  }
}
@media (max-width: 650px) {
  .block-t .basic-info-content,
  .block-b .basic-info-content {
    padding: unset;
  }
  .block-b .basic-info-content #num-block .row .row-item {
    margin-bottom: 15px;
    justify-content: space-between;
  }
  .block-b .basic-info-content #num-block .row {
    flex-direction: column;
  }
  .block-b .basic-info-content #num-block .row .row-item:first-child {
    margin-right: 0px;
  }
  .block-b .basic-info-content #num-block {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .row-line {
    height: 1px;
    background-color: #e0e0e0;
    flex: 1;
    margin: 0 10px;
  }
  .block-b .basic-info-content {
    background-color: transparent;
  }
  .block-b .basic-info-content #num-block .small-notice {
    display: block;
    color: #d11313;
    font-size: 0.9em;
  }
  .block-b .basic-info-content #num-block {
    margin: 0;
    width: 90%;
  }
  .block-b .basic-info-content #num-block .row .row-item:first-child {
    margin-right: 0px;
  }
  .block-b .basic-info-content #num-block .row .row-item .title {
    flex: unset;
    color: #727272;
  }
  .block-b .basic-info-content #num-block .row .row-item .title span {
    color: #727272;
  }
  .block-b .basic-info-content #num-block .row .row-item {
    margin-bottom: 27px;
    justify-content: space-between;
  }
  .block-b .basic-info-content #num-block .row:first-child {
    margin-bottom: 0px !important;
  }
  .block-b .basic-info-content #num-block .row .row-item .select-num {
    flex: unset;
    background-color: white;
    color: #727272;
    border: 2px solid #e0e0e0;
    height: 35px;
  }
  .block-b .basic-info-title {
    flex-direction: column;
  }
  .block-b {
    border: unset !important;
    height: unset;
    margin: 10px auto;
  }
  .block-b .basic-info-content #num-block .row {
    flex-direction: column;
  }
}
