#result {    
    display: flex;
    justify-content: flex-end;
    border: solid 1px #ccc;
    padding: 20px;
    //position: fixed;
    bottom: 0;
    width: 100%;
    right: 0;
    background-color: white;
    margin-top: 100px;
    #result-text {
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #submit-button { 
        margin-left: 15px;           
        border-radius: 50px;
        padding: 10px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #333333;
        color: white;
        cursor: pointer;
        
    }
    #submit-button:hover {
        background-color: #595959;
    }
    
    #pre-button {
        border-radius: 100px;
        padding: 10px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #333333;
        margin-left: 10px;
        color: white;
        background-color: white;
        color: #8f8f8f;
        cursor: pointer;
        font-weight: 400;
        .fas {
            margin-right: 5px;
        }
    }
}