#guest-info {
    border: solid 1px #cccccc;
    margin: 15px auto;
    line-height: 40px;
    padding: 0px;
    border-radius: 20px;
    color: #858585;
    display: flex;
    flex-wrap: wrap;
    font-weight: 300;
    p {
        padding: 0 20px;
    }

}
