#total-price {           
    background-color: #F8F3EF;
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    color: #553212;
    justify-content: center;
    #total-price-content {    
        display: flex;
        width: 80%;
        justify-content: space-between;
        align-items: flex-end;
        margin: 25px 0;
        font-weight: 300;
        border-bottom: 1px solid #f2e9e1;
        padding-bottom: 10px;
        .extra-block {    
            display: flex;
            align-items: flex-end;
            font-family: sans-serif;
            .fas {
                margin: 0 25px 3px 0;
            }
        }
        .price-block {
            display: flex;
            align-items: baseline;
            .text {
            }
            .price {
                font-size: 1.5em;
                padding: 3px 15px;
                font-weight: normal;
                background-color: #F2E9E1;
                border-radius: 30px;    
                margin: 0 4px;
                font-family: 'Abel', sans-serif;
            }
        }
        

    }
    .error-message{
      
    }
}
@media(max-width:800px) {
    #total-price #total-price-content {
        flex-direction: column;
        align-items: flex-end;    
        width: 90%;
        margin: 13px 0;
        padding-bottom: 5px;
    }
    #total-price #total-price-content .price-block {
        margin-top: 8px;    
    }
    #total-price #total-price-content .extra-block {
        width: 100%;

    }
    #total-price #total-price-content .extra-block .fas {
        margin: 0 10px 3px 0;
    }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #eaa05e;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 10px;
    left: 10px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 30px;
    height: 30px;
    opacity: 0;
  }
}
