.About {    
    width: 1000px;
    margin: 100px auto;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 80px 30px 120px 30px;
    .title {        
        text-align: center;
        display: inline-block;
        font-size: 1.8em;
        margin-bottom: 50px;
        &:after {
            content: "";
            display: block;
            width: 120px;
            height: 3px;
            background-color: #204865;
        }
    }
    p {
        color: #4d4d4d;
    }
}
@media (max-width: 550px) {
    .About {
        width: 95%;
        margin: 10px auto;
    }
}